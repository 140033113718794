body {
  margin: 0;
  padding: 0;
  font-family: "poppins" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.slider-control-centerleft > button, .slider-control-centerright > button {
  box-shadow: 0 2px 11px rgba(36, 36, 36, 0.5);
  border-radius: 4px;
  padding: 10px;
  border: none;
  cursor: pointer;
  color: rgba(90, 90, 90, 0.85);
  background-color: white;
}
@media (max-width: 700px) {
  .slider-control-centerleft > button, .slider-control-centerright > button {
    display: none !important;
  }
}

.slider-slide:first-child > div {
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}

.slider-slide:last-child > div {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

.slider-control-bottomcenter {
  display: none;
}

.ReactModal__Overlay {
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.59) !important;
}

.width-100 {
  width: 100%;
}

.ReactModal__Content {
  width: 100%;
  height: 100%;
  border: none !important;
  padding: 0px !important;
  position: unset !important;
  top: unset !important;
  left: unset !important; 
  right: unset !important; 
  bottom: unset !important; 
}

.action-buttons::-webkit-scrollbar
{
  width: 0px;
}